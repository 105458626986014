import {
	isBlank
}
from 'voUtils/tools.js';

import {
	favCount, onFavUpdate
}
from 'voUtils/FavStore.js';

export default {

	data: {
		name: undefined,
	},
	mounted: function () {
		onFavUpdate(() => {
			this.$forceUpdate();
		});

		new Mmenu(document.querySelector('#mobilemenu'), {
			onClick: {
				close: true
			}
		});

		/*document.addEventListener('click', function (evnt) {
			var anchor = evnt.target.closest('a[href^="#/"]');
			if (anchor) {
				alert("Thank you for clicking, but that's a demo link.");
				evnt.preventDefault();
			}
		});*/

	},

	methods: {

		navbarToggle: function () {

			var nav = this.$el;

			if (nav.className === "vo-nav-bar") {
				nav.className += " opened";
			} else {
				nav.className = "vo-nav-bar";
			}


		},
		favCount: favCount,
		goSearch: function () {
			//window.location.href = '#mm-0';
			if (isBlank(this.name)) {
				window.location.href = 's';
			} else {
				window.location.href = 's#?name=' + encodeURI(this.name);

			}

			//$("#mobilemenu").hide();
			/*$('#mm-0').css('transform', 'none');
			$("#mm-0").show();*/

		}



	}
};