import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';


import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

export default {
	data: function () {
		return {
			units: []
		};
	},
	// props: {
	// 	filter: Object,
	// 	sorting: String,
	// 	max: String
	// },
	beforeMount: function () {
		var results = this.$el.getElementsByClassName('unit-list-section');
		if (results.length) {
			this.resultFields = findResultFields(results[0].innerHTML, this.$el);
		}
		let el = document.querySelector("[data-beds]");
		let el2 = document.querySelector("[data-region-id]");
		var beds = 2;
		var regionId;
		if (el) {
			beds = parseInt(el.getAttribute('data-beds'), 10);
			regionId = el2.getAttribute('data-region-id');
			console.log('region path::' + regionId);
		} else {
			console.log('element is not found..................');
		}
		var req = {
			fields: this.resultFields,
			filter: {
				adults: beds
			},
			sorting: 'code',
			max: 4
		};
		/*if (regionId) {
			req.filter.region.id = regionId;
		}*/

		doReq('searchUnits', req)
			.then((res) => {
				console.log('result::' + res.units);
				this.units = res.units;
			});

	},
	updated: function () {
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();
	},
	methods: {
		getUnitSearchTarget: function (id) {
			if (window.screen.width < 768) {
				//probably phone?
				return '_self';
			} else {
				return 'unit_' + id;
			}
		}
	}
};