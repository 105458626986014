<template>
    <a href="javascript:0;"
        data-toggle="popover"
        title="Obligatorische Nebenkosten"
        data-trigger="hover"
        data-placement="top"
        data-html="true"
        :data-content="getPriceText">
        <i class="fas fa-question-circle pricehelp"></i>
    </a>

</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'price-help',
    	props: {
    		pricelist: Array
    	},
    	data: function() {
    		return {
    			totalStars: 5
    		};
    	},
    	computed: {
    		getPriceText: function() {
    			var priceText = '';
    			priceText = "<ul>";
    			priceText += "<li>";
    			priceText += "<i class='fas fa-check'></i> Endreinigung des jeweiligen Objektes"
    			priceText += "</li>";

    			priceText += "<li>";
    			priceText += "<i class='fas fa-check'></i> Servicepauschale 2,00 €"
    			priceText += "</li>";

    			priceText += "<li>";
    			priceText += "<i class='fas fa-check'></i> Vertragsgebühr 19,90 €"
    			priceText += "</li>";

    			priceText += "</ul>";
    			if (this.pricelist) {
    				var i;

    				for (i = 0; i < this.pricelist.length; i++) {
    					/*
    					if (this.pricelist[i].type == 'FINALCLEANING') {
    						priceText += "<li>";
    						priceText += "Endreinigung " + this.pricelist[i].value / 100 + "€";
    						priceText += "</li>";
    					}
    					if (this.pricelist[i].service == '23669') {
    						priceText += "<li>";
    						priceText += "Vertragsgebühr " + this.pricelist[i].value / 100 + "€";
    						priceText += "</li>";
    					} */


    					//console.log('type:' + this.pricelist[i].name);
    				}


    			} else {
    				//priceText = "keine Angaben möglich";
    			}

    			return priceText;
    		}
    	}
    };
</script>
<style scoped>
    .pricehelp {
    	font-size: 13px;
    	color: #666666;

    }
</style>